import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useCreateChatChannel } from "@src/appV2/Facilities/Chat/api/useCreateChatChannel";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { generatePath, useHistory } from "react-router-dom";

import { Button } from "../../components/Button";

interface ChatWithWorkplaceButtonProps {
  workplaceId: string;
}

function ChatWithWorkplaceButton({ workplaceId }: ChatWithWorkplaceButtonProps) {
  const worker = useDefinedWorker();
  const history = useHistory();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel();

  return (
    <Button
      fullWidth
      startIconType="text-bubble"
      variant="outlined"
      disabled={isCreatingChatChannel}
      sx={(theme) => ({ boxShadow: theme.shadows[2] })}
      onClick={async () => {
        await createChatChannel({
          facilityId: workplaceId,
          agentId: worker.userId,
        });

        history.push(
          generatePath(DeprecatedGlobalAppV1Paths.CHAT, {
            facilityId: workplaceId,
          })
        );
      }}
    >
      Chat with workplace
    </Button>
  );
}

export default ChatWithWorkplaceButton;
