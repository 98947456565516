import { DotSeparatedList, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { WorkerShiftRequestCard } from "../components/RequestCard";
import ChatWithWorkplaceButton from "./ChatWithWorkplaceButton";

interface SentHomeRequestCardProps {
  shiftTimeSlot: ShiftPeriod;
  shiftStart: string;
  shiftEnd: string;
  shiftAgentReq: string;
  workplaceName: string;
  totalEarnings: number;
  workplaceId: string;
}

export function SentHomeRequestCard(props: SentHomeRequestCardProps) {
  const {
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    workplaceName,
    totalEarnings,
    workplaceId,
  } = props;

  return (
    <WorkerShiftRequestCard
      shiftTimeSlot={shiftTimeSlot}
      shiftStart={shiftStart}
      shiftEnd={shiftEnd}
      pills={[
        <Pill
          key="pending-sent-home-request"
          label="Pending sent home request"
          color={(theme) => theme.palette.intent?.neutral.text}
          backgroundColor={(theme) => theme.palette.intent?.neutral.background}
          iconColor={(theme) => theme.palette.intent?.neutral.icon}
        />,
      ]}
      details={
        <>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            <DotSeparatedList>{[shiftAgentReq, workplaceName].filter(Boolean)}</DotSeparatedList>
          </Text>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            {formatDollarsAsUsd(totalEarnings)} total
          </Text>
        </>
      }
    >
      <Stack spacing={4}>
        <ChatWithWorkplaceButton workplaceId={workplaceId} />
      </Stack>
    </WorkerShiftRequestCard>
  );
}
