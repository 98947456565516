import { BottomSheet, BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useCancelShift } from "@src/appV2/Shifts/Shift/ShiftState/api/useCancelShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { differenceInMinutes } from "date-fns";
import { useMemo } from "react";

import { DialogFooter } from "../../../components/DialogFooter";
import { CancelShiftDescription } from "./CancelShiftDescription";
import { ShiftCancelledConfirmationBottomSheet } from "./ShiftCancelledConfirmationBottomSheet";

interface CancelShiftBottomSheetProps {
  modalState: UseModalState;
  isNoCallNoShow: boolean;
  shiftStartDate: Date;
  shiftId: string;
  currentDate: Date;
}

export function CancelShiftBottomSheet(props: CancelShiftBottomSheetProps) {
  const { modalState, isNoCallNoShow, shiftStartDate, shiftId, currentDate } = props;

  const shiftCancelledConfirmationModalState = useModalState();

  const worker = useDefinedWorker();

  const { mutateAsync: cancelShift, isLoading: isLoadingCancelShift } = useCancelShift();

  const { showErrorToast } = useToast();

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  const shiftCancellationLeadTimeInHours = (
    differenceInMinutes(shiftStartDate, currentDate) / 60
  ).toFixed(2);

  const {
    data: attendanceScoreProfile,
    isSuccess: attendanceScoreProfileSuccess,
    isLoading: attendanceScoreProfileLoading,
  } = useGetAttendanceScoreProfile(worker.userId, {
    enabled: modalState.modalIsOpen,
  });

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  async function handleCancelShift() {
    try {
      await cancelShift({
        shiftId,
        reasonType: "NONE",
        reasonDescription: "",
        isNative: false,
      });

      modalState.closeModal();
      shiftCancelledConfirmationModalState.openModal();

      logEvent("Shift Cancelled", {
        shiftId,
      });
    } catch (error) {
      showErrorToast("Something went wrong while cancelling your shift. Please try again.");
      logError("Error cancelling shift", {
        error,
        metadata: {
          shiftId,
        },
      });
    }
  }

  const cancelShiftPolicy = attendanceScoreProfile?.policy?.cancelShift?.find((policy) => {
    const [minHours, maxHours] = policy.leadTime;
    const leadTimeHours = Number(shiftCancellationLeadTimeInHours);
    return (
      (minHours === null || leadTimeHours >= minHours) &&
      (maxHours === null || leadTimeHours < maxHours)
    );
  });

  const pointsToDeduct = cancelShiftPolicy?.points ?? 0;

  const newAttendanceScore = useMemo(() => {
    if (!attendanceScoreProfileSuccess || reliabilityRankingExperiment.enabled) {
      return undefined;
    }

    if (isClipboardScoreEnabled) {
      return (attendanceScoreProfile.clipboardScore ?? 0) + pointsToDeduct;
    }

    return (attendanceScoreProfile.score ?? 0) + pointsToDeduct;
  }, [
    isClipboardScoreEnabled,
    attendanceScoreProfile,
    pointsToDeduct,
    attendanceScoreProfileSuccess,
    reliabilityRankingExperiment,
  ]);

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <>
      <BottomSheet modalState={modalState}>
        <BottomSheetIllustrationContent
          illustrationType="cancellation"
          title="Cancel your shift?"
          description={
            <CancelShiftDescription
              scoreType={scoreType}
              isNoCallNoShow={isNoCallNoShow}
              isSuccess={attendanceScoreProfileSuccess}
              isLoading={attendanceScoreProfileLoading}
              pointsToDeduct={pointsToDeduct}
              newAttendanceScore={newAttendanceScore}
            />
          }
        />
        <DialogFooter orientation="vertical" sx={{ marginTop: 10 }}>
          <LoadingButton
            fullWidth
            variant="outlined"
            size="large"
            sx={{
              color: (theme) => theme.palette.error.light,
              borderColor: (theme) => theme.palette.border?.primary,
            }}
            isLoading={isLoadingCancelShift}
            disabled={attendanceScoreProfileLoading}
            onClick={async () => {
              await handleCancelShift();
            }}
          >
            Cancel shift
          </LoadingButton>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{
              color: (theme) => theme.palette.primary.dark,
            }}
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Don&apos;t cancel
          </Button>
        </DialogFooter>
      </BottomSheet>
      <ShiftCancelledConfirmationBottomSheet
        modalState={shiftCancelledConfirmationModalState}
        pointsDeducted={pointsToDeduct}
        newAttendanceScore={newAttendanceScore}
      />
    </>
  );
}
