import { Stack } from "@mui/material";
import { type SentHomeRequestsResponse } from "@src/appV2/Agents/api/useSentHomeRequests";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { SentHomeRequestCard } from "./Card";

interface SentHomeRequestsListProps {
  pendingSentHomeRequests: SentHomeRequestsResponse;
}

export function SentHomeRequestsList(props: SentHomeRequestsListProps) {
  const { pendingSentHomeRequests } = props;

  return (
    <Stack spacing={6}>
      {pendingSentHomeRequests.map((request) => {
        return (
          <SentHomeRequestCard
            key={request.shift._id}
            shiftTimeSlot={request.shift.name as ShiftPeriod}
            shiftStart={request.shift.start}
            shiftEnd={request.shift.end}
            shiftAgentReq={request.shift.agentReq}
            workplaceName={request.shift.facility.name}
            totalEarnings={request.earnings}
            workplaceId={request.shift.facilityId ?? ""}
          />
        );
      })}
    </Stack>
  );
}
