import { TextSkeleton } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";

interface CancelShiftDescriptionProps {
  isLoading: boolean;
  isSuccess: boolean;
  pointsToDeduct: number;
  newAttendanceScore?: number;
  isNoCallNoShow: boolean;
  scoreType: string;
}

export function CancelShiftDescription(props: CancelShiftDescriptionProps) {
  const { isLoading, isSuccess, pointsToDeduct, newAttendanceScore, isNoCallNoShow, scoreType } =
    props;

  if (isLoading) {
    return (
      <>
        <TextSkeleton variant="h4" width="20ch" />
        <TextSkeleton variant="h4" width="20ch" />

        {/* eslint-disable-next-line no-restricted-syntax */}
        <br />
        <TextSkeleton variant="h4" width="20ch" />
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        This cancellation will penalize your {scoreType} by <strong>{pointsToDeduct}</strong>
        {isNoCallNoShow ? " as it is considered a no-show" : "."}
        {isDefined(newAttendanceScore) && (
          <>
            {/* eslint-disable-next-line no-restricted-syntax */}
            <br />
            {/* eslint-disable-next-line no-restricted-syntax */}
            <br />
            Your new {scoreType} will be <strong>{newAttendanceScore}</strong>.
          </>
        )}
      </>
    );
  }

  return null;
}
