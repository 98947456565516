import { Card, type CardProps } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import type { Ref } from "react";
import { forwardRef } from "react";

interface ShiftDetailsInnerCardProps extends CardProps {}

function BaseShiftDetailsInnerCard(props: ShiftDetailsInnerCardProps, ref: Ref<HTMLDivElement>) {
  const { sx, ...restProps } = props;

  return <Card {...restProps} ref={ref} sx={mergeSxProps({ maxHeight: "100%" }, sx)} />;
}

export const ShiftDetailsInnerCard = forwardRef(BaseShiftDetailsInnerCard);
