import { HorizontalScrollView, PageFooter } from "@clipboard-health/ui-components";
import { Title, useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { isAfter, parseISO } from "date-fns";
import { useParams } from "react-router-dom";

import { PageWrapper } from "../../components/PageWrapper";
import { getGradientByTimeSlot } from "../../Shift/getGradientByTimeSlot";
import { formatTime } from "../../utils/formatTime";
import { PageContent } from "../components/PageContent";
import { CancelShiftBottomSheet } from "./components/CancelShiftBottomSheet";
import { ClockInButton } from "./components/ClockInButton";
import { ShiftDetailsHeaderWrapper } from "./components/HeaderWrapper";
import { ShiftDetailsParkingCard } from "./components/ParkingCard";
import { ShiftTimingPill } from "./components/ShiftTimingPill";

export function ShiftDetailsPage() {
  const currentDate = useCurrentDate(1000);

  const cancelShiftModalState = useModalState();

  const { shiftId } = useParams<{ shiftId: string }>();

  const {
    data: shiftData,
    isSuccess: isShiftSuccess,
    refetch: refetchShift,
  } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const shift = shiftData?.response;

  const shiftStartDate = isDefined(shift) ? parseISO(shift.start) : new Date();

  const formattedStartTime = isShiftSuccess ? formatTime(shiftStartDate, shift?.facility.tmz) : "";

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper
        sx={(theme) => ({
          background: isDefined(shift)
            ? getGradientByTimeSlot({
                timeSlot: shiftPeriodToTimeSlot[shift.name],
                theme,
              })
            : undefined,
        })}
      >
        <ShiftDetailsHeaderWrapper />
        <PageContent>
          <PullToRefresh
            onRefresh={async () => {
              await refetchShift();
            }}
          />

          <Stack
            spacing={6}
            sx={{
              marginTop: 8,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <ShiftTimingPill currentDate={currentDate} shiftStartDate={shiftStartDate} />
            </Box>

            <Title component="h3" variant="h3">
              {isDefined(shift?.agentReq)
                ? `Prepare for your ${shift.agentReq} shift at ${formattedStartTime}`
                : `Prepare for your shift at ${formattedStartTime}`}
            </Title>

            <HorizontalScrollView stretch sx={{ paddingLeft: 2, height: "auto", marginTop: 2 }}>
              {isDefined(shift) && <ShiftDetailsParkingCard shift={shift} />}
            </HorizontalScrollView>
          </Stack>
        </PageContent>

        <PageFooter>
          {isDefined(shift) && (
            <ClockInButton
              shift={shift}
              onCancelShift={() => {
                cancelShiftModalState.openModal();
              }}
            />
          )}
        </PageFooter>
      </PageWrapper>

      {isDefined(shift?.start) && isDefined(shiftId) ? (
        <CancelShiftBottomSheet
          modalState={cancelShiftModalState}
          isNoCallNoShow={
            !isDefined(shift.clockInOut?.start) && isAfter(currentDate, shiftStartDate)
          }
          currentDate={currentDate}
          shiftStartDate={shiftStartDate}
          shiftId={shiftId}
        />
      ) : null}
    </ThemeProvider>
  );
}
