import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function SentHomeRequestsEmptyState() {
  return (
    <Stack
      sx={{
        flex: 1,
        flexGrow: 1,
        alignItems: "center",
      }}
      justifyContent="center"
      spacing={5}
    >
      <Title semibold variant="h4" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
        You don&apos;t have any sent home requests
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        Sent home requests will appear here when a facility sends you home early from a shift
      </Text>
    </Stack>
  );
}
