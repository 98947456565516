import { useModalState } from "@clipboard-health/ui-react";
import { type Shift } from "@src/appV2/Shifts/Shift/types";

import { Button } from "../../../components/Button";
import { ClockInBottomSheet } from "./ClockInBottomSheet";

interface ClockInButtonProps {
  onCancelShift: () => void;
  shift: Shift;
}

export function ClockInButton(props: ClockInButtonProps) {
  const { onCancelShift, shift } = props;

  const clockInBottomSheetModalState = useModalState();

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        size="large"
        endIconType="chevron-down"
        onClick={() => {
          clockInBottomSheetModalState.openModal();
        }}
      >
        Clock-in options
      </Button>

      <ClockInBottomSheet
        modalState={clockInBottomSheetModalState}
        shift={shift}
        onCancelShift={() => {
          onCancelShift();
        }}
      />
    </>
  );
}
