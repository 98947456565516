// eslint-disable-next-line no-restricted-imports
import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function SentHomeRequestsLoadingPageState() {
  return (
    <Stack spacing={6} aria-label="Loading sent home requests">
      {Array.from({ length: 3 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={index}>
          <CardContent sx={{ padding: 6 }}>
            <Stack spacing={6}>
              <Stack direction="row" spacing={2}>
                <Skeleton variant="rounded" width={180} height={24} />
              </Stack>

              <Stack direction="row" spacing={5}>
                <Skeleton variant="circular" width={48} height={48} />
                <Stack spacing={3} flexGrow={1}>
                  <Stack spacing={2}>
                    <Skeleton variant="text" width={160} />
                    <Skeleton variant="text" width={220} />
                  </Stack>
                  <Stack spacing={1}>
                    <Skeleton variant="text" width="70%" />
                    <Skeleton variant="text" width="50%" />
                    <Skeleton variant="text" width="60%" />
                  </Stack>
                </Stack>
              </Stack>

              <Skeleton variant="text" sx={{ width: "100%" }} />

              <Stack spacing={4}>
                <Skeleton variant="text" width={240} />
                <Skeleton variant="text" width={340} />
                <Skeleton variant="rectangular" height={48} sx={{ mt: 4 }} />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}
