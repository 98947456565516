import { BottomSheetWithIntent } from "@clipboard-health/ui-components";
import { type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { CurrentShiftStage } from "@src/appV2/redesign/Shift/constants";
import { getShiftCurrentStage } from "@src/appV2/redesign/Shift/WorkerShift/getShiftCurrentStage";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { useGetShiftState } from "@src/appV2/Shifts/Shift/ShiftState/api/useGetShiftState";
import { isAfter, parseISO, startOfMinute, subMinutes } from "date-fns";
import { useHistory } from "react-router-dom";

import { getSentHomeRequestCreatePath } from "../../CreateSentHomeRequest/path";
import { type ShiftTimekeepingDetails } from "../types";
import { mapShiftStateDependencies } from "../utils/mapShiftStateDependencies";
import { UpdateEtaBottomSheet } from "./UpdateEtaBottomSheet";

interface ClockInBottomSheetProps {
  modalState: UseModalState;
  onCancelShift: () => void;
  shift: ShiftTimekeepingDetails;
}

export function ClockInBottomSheet(props: ClockInBottomSheetProps) {
  const { modalState, onCancelShift, shift } = props;

  const history = useHistory();

  const currentDate = useCurrentDate(1000);

  const shiftStateDependencies = isDefined(shift?._id)
    ? mapShiftStateDependencies(shift)
    : undefined;

  const { data: shiftState, isSuccess: isShiftStateSuccess } = useGetShiftState(
    { shiftStateDependencies },
    {
      enabled: isDefined(shiftStateDependencies?._id) && modalState.modalIsOpen,
    }
  );

  const shiftStage = getShiftCurrentStage(shift);
  const isEarlyClockInEnabled = shiftState?.metadata.isEarlyClockInEnabled ?? false;
  const earlyClockInEnabledInMinutes = shiftState?.metadata.earlyClockInEnabledInMinutes ?? 0;
  const shiftStartDate = shift?.start ? parseISO(shift.start) : null;

  const earliestClockInTime =
    isDefined(shiftStartDate) && isEarlyClockInEnabled
      ? subMinutes(shiftStartDate, earlyClockInEnabledInMinutes)
      : shiftStartDate;

  const formattedClockInTime = isDefined(earliestClockInTime)
    ? formatTime(earliestClockInTime, shift.facility.tmz)
    : "";

  const isAfterEarliestClockInTime = earliestClockInTime
    ? isAfter(startOfMinute(currentDate), earliestClockInTime)
    : false;

  function getBottomSheetIntentContent() {
    if (
      !isDefined(shiftStartDate) ||
      isAfterEarliestClockInTime ||
      shiftStage !== CurrentShiftStage.NOT_STARTED ||
      !isShiftStateSuccess
    ) {
      return undefined;
    }

    return `Clock in enabled at ${formattedClockInTime}`;
  }

  const updateEtaModalState = useModalState();

  return (
    <>
      <BottomSheetWithIntent
        modalState={modalState}
        intent="warning"
        intentContent={getBottomSheetIntentContent()}
        intentIcon="triangle-exclamation"
        footer={
          <DialogFooter
            orientation="vertical"
            onClose={() => {
              modalState.closeModal();
            }}
          />
        }
      >
        <Stack
          sx={{
            paddingX: 4,
          }}
        >
          <Stack spacing={4}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={!isAfterEarliestClockInTime}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOCK_IN_NOW_TAPPED, {
                  shiftId: shift._id,
                });
                modalState.closeModal();
              }}
            >
              Clock in now
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_LATE_FOR_SHIFT_TAPPED, {
                  shiftId: shift._id,
                });
                updateEtaModalState.openModal();
                modalState.closeModal();
              }}
            >
              I&apos;m late for the shift
            </Button>
            {isAfterEarliestClockInTime && (
              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_SENT_HOME_TAPPED, {
                    shiftId: shift._id,
                  });

                  history.push(getSentHomeRequestCreatePath(shift._id));
                  modalState.closeModal();
                }}
              >
                I was sent home
              </Button>
            )}
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CANCEL_SHIFT_TAPPED, {
                  shiftId: shift._id,
                });
                onCancelShift();
                modalState.closeModal();
              }}
            >
              Cancel my shift
            </Button>
          </Stack>
        </Stack>
      </BottomSheetWithIntent>
      {isDefined(shift) && isDefined(shiftStartDate) && isDefined(shift.facility.userId) && (
        <UpdateEtaBottomSheet
          modalState={updateEtaModalState}
          shiftStartDate={shiftStartDate}
          currentDate={currentDate}
          shiftId={shift._id}
          shiftName={shift.name}
          workplaceId={shift.facility.userId}
        />
      )}
    </>
  );
}
