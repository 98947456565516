import { getTheme } from "@clipboard-health/ui-theme";
import { Alert, ThemeProvider } from "@mui/material";
import { useSentHomeRequests } from "@src/appV2/Agents/api/useSentHomeRequests";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useRef } from "react";

import { PageContent } from "../components/PageContent";
import { SentHomeRequestsEmptyState } from "./EmptyState";
import { SentHomeRequestsList } from "./List";
import { SentHomeRequestsLoadingPageState } from "./LoadingPageState";

export function SentHomeRequestsPage() {
  const {
    data: sentHomeRequestsData,
    refetch: refetchSentHome,
    isLoading,
    isSuccess,
    isError,
  } = useSentHomeRequests();

  const pendingSentHomeRequests = sentHomeRequestsData ?? [];

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <StickyScrollPageHeader
          title="Sent Home Requests"
          isLoading={isLoading}
          scrollContainerRef={scrollRef}
        />
        <PageContent ref={scrollRef}>
          <PullToRefresh
            onRefresh={async () => {
              await refetchSentHome();
            }}
          />
          {isLoading && <SentHomeRequestsLoadingPageState />}
          {isError && <Alert severity="error">Error loading sent home requests.</Alert>}
          {isSuccess &&
            (pendingSentHomeRequests.length > 0 ? (
              <SentHomeRequestsList pendingSentHomeRequests={pendingSentHomeRequests} />
            ) : (
              <SentHomeRequestsEmptyState />
            ))}
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
