import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../../components/IconButton";

interface ShiftDetailsHeaderWrapperProps {
  onBackClick?: () => void;
}

export function ShiftDetailsHeaderWrapper(props: ShiftDetailsHeaderWrapperProps) {
  const { onBackClick } = props;
  const history = useHistory();

  function handleBackClick() {
    if (onBackClick) {
      onBackClick();
    } else {
      history.goBack();
    }
  }

  return (
    <PageHeaderWrapper sx={{ background: "transparent" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        position="relative"
        height={(theme) => theme.spacing(10)}
        px={3}
      >
        <Stack position="absolute" left={(theme) => theme.spacing(3)}>
          <IconButton
            iconType="arrow-left"
            variant="outlined"
            onClick={() => {
              handleBackClick();
            }}
          />
        </Stack>
      </Stack>
    </PageHeaderWrapper>
  );
}
